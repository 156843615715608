import React from "react";

import { WebHeroCollectionItem } from "resources/AudiotekaApi";
import { Link } from "ui/link";

import css from "./collection-item.module.scss";

export const CollectionItem = ({ item }: { item: WebHeroCollectionItem }) => {
  return (
    <Link className={css.collectionItem} route={item.type} params={{ id: item.slug || item.id }}>
      <img
        // eslint-disable-next-line react/no-unknown-property
        fetchPriority="high"
        src={`${item.image_url}?w=150&auto=format`}
        alt={item.name}
      />
    </Link>
  );
};
