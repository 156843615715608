import React from "react";

import Link, { LinkParams } from "components/link";
import shouldBeADarkColor from "utils/shouldBeADarkColor";
import classNames from "classnames";

import css from "./teaser-group.module.scss";

type TeaserGroupProps = {
  isMobileReveal: boolean;
  label: string;
  bannerUrl: string;
  itemCount?: string;
  labelColor: string;
  link: LinkParams;
  accessibilityLabel: string;
};

const TeaserGroup = ({
  isMobileReveal,
  label,
  bannerUrl,
  itemCount,
  labelColor = "#eeebf2",
  link,
  accessibilityLabel,
}: TeaserGroupProps) => (
  <div
    className={classNames(css.teaserGroup, {
      [css.teaserGroup__mobileNotReveal]: !isMobileReveal,
    })}
  >
    <Link route={link.route} params={link.params} href={link.href} title={accessibilityLabel}>
      <img className={css.image} loading="lazy" src={`${bannerUrl}?h=174`} alt={accessibilityLabel} />
      <div
        className={classNames(css.label, {
          [css.label__dark]: shouldBeADarkColor(labelColor),
        })}
        style={{ "--bg-color": `#${labelColor}` } as React.CSSProperties}
      >
        {label} {itemCount && <span>{itemCount}</span>}
      </div>
    </Link>
  </div>
);

export default TeaserGroup;
