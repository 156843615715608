import { useLocale } from "next-intl";
import { usePathname } from "next/navigation";

import { BreadcrumbsItem } from "ui/breadcrumbs/breadcrumbs.types";

import { buildLink } from "./buildLink";

export function useSchema(items: BreadcrumbsItem[] = []) {
  const locale = useLocale();
  const currentPathname = usePathname();

  return {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Audioteka",
        item: `${process.env.SITE_URL}/${locale}/`,
      },
      ...items.map((item, index) => ({
        "@type": "ListItem",
        position: index + 2,
        name: item.name,
        item: item.link
          ? buildLink(item.link.route, locale, item.link.params, true)
          : `${process.env.SITE_URL}${currentPathname}`,
      })),
    ],
  };
}
