import React, { CSSProperties } from "react";

import { ProductGridPromoSection } from "resources/AudiotekaApi";
import Markdown from "components/markdown";
import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import TeaserList from "components/teaser-list";
import { PricelessTeaser } from "components/teaser/priceless-teaser";
import { ButtonLink } from "ui/button/button-link";
import { breakpoint } from "modules/DesignSystem/breakpoint";

import { trackCTAHomeEvent } from "../../home.utils";
import { PromoTop } from "./promo-top/promo-top";
import css from "./product-grid-promo.module.scss";

export const ProductGridPromo = ({
  lazyLoading,
  section,
}: {
  lazyLoading: boolean;
  section: ProductGridPromoSection;
}) => {
  const [element] = section.elements;

  const products = section._embedded["app:product"].slice(0, 5);

  return (
    <div className={css.productGridPromo} style={{ color: `#${element.description_color}` } as CSSProperties}>
      <picture className={css.backgroundPicture}>
        <source media={`(min-width: ${breakpoint.tablet})`} srcSet={`${element.background_image_url}?auto=format`} />
        <img loading="lazy" src={`${element.mobile_background_image_url}?auto=format`} alt="" />
      </picture>
      <ContainerBase className={css.content}>
        <PromoTop
          color={element.title_color}
          sideButton={
            element.side_button_deeplink && {
              color: element.side_button_label_color ? `#${element.side_button_label_color}` : null,
              deeplink: element.side_button_deeplink,
              label: element.side_button_label,
            }
          }
          title={element.title}
        />
        <TeaserList
          className={css.teaserList}
          compact
          elements={products}
          trackingId={element.tracking_id || section.id}
          renderTeaser={({ product, onClick }) => (
            <PricelessTeaser product={product} colorless lazyLoading={lazyLoading} onClick={onClick} />
          )}
        />
        {element.description && (
          <div className={css.description}>
            <Markdown text={element.description} />
          </div>
        )}
        {element.button_deeplink && (
          <ButtonLink
            className={css.action}
            {...deeplinkTranslator(element.button_deeplink)}
            style={{ backgroundColor: `#${element.button_color}`, color: `#${element.button_label_color}` }}
            onClick={() => trackCTAHomeEvent(element.title, element.button_label)}
          >
            {element.button_label}
          </ButtonLink>
        )}
      </ContainerBase>
    </div>
  );
};
