import React from "react";

import { WebHeroCollectionItem } from "resources/AudiotekaApi";

import { CollectionItem } from "./collection-item";
import css from "./collection.module.scss";

export const Collection = ({ items }: { items: WebHeroCollectionItem[] }) => {
  const itemsTop = items.slice(0, 5);
  const itemsBottom = items.slice(5, 10);

  return (
    <div className={css.collection}>
      <div className={css.section}>
        {itemsTop.map((item) => (
          <CollectionItem key={item.id} item={item} />
        ))}
      </div>
      <div className={css.section}>
        {itemsBottom.map((item) => (
          <CollectionItem key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};
