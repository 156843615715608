import React from "react";
import escape from "lodash/escape";
import { useTranslations } from "next-intl";

import { NewIcon } from "ui/NewIcon";

import css from "./onboarding.module.scss";

export function Onboarding() {
  const t = useTranslations();

  const items = [
    {
      icon: "headphones-alt" as const,
      title: t("home.onboarding.item1.title"),
      description: t("home.onboarding.item1.description"),
    },
    {
      icon: "mobile-phone" as const,
      title: t("home.onboarding.item2.title"),
      description: t("home.onboarding.item2.description"),
    },
    {
      icon: "hand-with-hearts" as const,
      title: t("home.onboarding.item3.title"),
      description: t("home.onboarding.item3.description"),
    },
    {
      icon: "fire" as const,
      title: t("home.onboarding.item4.title"),
      description: t("home.onboarding.item4.description"),
      link: t("home.onboarding.item4.link"),
    },
  ];

  return (
    <div className={css.onboarding}>
      <h2 className={css.title}>{t("home.onboarding.title")}</h2>
      <div className={css.list}>
        {items.map(({ icon, title, description, link }) => (
          <div className={css.item} key={icon}>
            <div className={css.iconBox}>
              <NewIcon icon={icon} width="24" height="24" />
            </div>
            <p
              className={css.itemTitle}
              dangerouslySetInnerHTML={{
                __html: escape(title).replace(
                  /\[\[(.*?)]]/g,
                  link
                    ? `<a class="${css.pink}" href="${link}" target="_blank">$1</a>`
                    : `<span class="${css.pink}">$1</span>`
                ),
              }}
            />
            <p className={css.itemDescription}>{description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
