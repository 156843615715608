import React from "react";
import NextImage from "next/image";
import { useTranslations } from "next-intl";
import classNames from "classnames";

import { Breakpoint } from "modules/DesignSystem/breakpoint";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import Markdown from "components/markdown";
import { NewIcon } from "ui/NewIcon";
import { Link } from "components/link";

import backgroundImg from "./img/background.jpg";
import backgroundMobileImg from "./img/background-m.jpg";
import css from "./about-club.module.scss";

const Icon = ({ icon, ...props }: React.ComponentProps<typeof NewIcon>) => (
  <div className={css.iconWrapper}>
    <NewIcon icon={icon} {...props} />
  </div>
);

export function AboutClub() {
  const t = useTranslations();

  return (
    <div className={css.aboutClub}>
      <picture className={css.bgPicture}>
        <source media={`(min-width: ${Breakpoint.lg}px)`} srcSet={backgroundImg.src} />
        <img loading="lazy" src={backgroundMobileImg.src} alt="" />
      </picture>
      <ContainerBase className={css.content}>
        <h3 className={css.title}>{t("about_club.title")}</h3>
        <NextImage
          className={css.image}
          width="450"
          height="468"
          src="/img/about-club-phones.png"
          alt="Audioteka Club"
        />
        <ul className={classNames(css.list, css.list__first)}>
          <li className={css.item}>
            <Icon icon="touchscreen" />
            <Markdown text={t("about_club.item1")} />
          </li>
          <li className={css.item}>
            <Icon icon="headphones-alt" />
            <Markdown text={t("about_club.item2")} />
          </li>
          <li className={css.item}>
            <Icon icon="promo" />
            <Markdown text={t("about_club.item3")} />
          </li>
        </ul>
        <ul className={css.list}>
          <li className={css.item}>
            <Icon icon="share" />
            <Markdown text={t("about_club.item4")} />
          </li>
          <li className={css.item}>
            <Icon icon="kid" />
            <Markdown text={t("about_club.item5")} />
          </li>
          <li className={css.item}>
            <Icon icon="fire" />
            <Markdown text={t("about_club.item6")} />
          </li>
        </ul>
        <Link className={css.moreButton} href={t("about_club.more_link")}>
          {t("about_club.more")}
        </Link>
      </ContainerBase>
    </div>
  );
}
