import React from "react";
import { useTranslations } from "next-intl";

import css from "./page-title.module.scss";

export function PageTitle() {
  const t = useTranslations();

  return <h1 className={css.title}>{t("home.title")}</h1>;
}
