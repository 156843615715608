"use client";

import React, { forwardRef } from "react";
import memoize from "lodash/memoize";
import styled from "styled-components";
import { lightStrongAdditional } from "modules/DesignSystem/colors";
import { MEDIA_QUERY } from "modules/DesignSystem/breakpoint";
import { extractRGB, getContrastColor } from "utils/color";
import Link, { LinkParams } from "components/link";

// eslint-disable-next-line no-shadow
export enum ButtonVariant {
  default,
  transparent,
  inverted,
  outlined,
}

interface BtnProps {
  as?: string | React.FC | React.Component;
  color?: string;
  compact?: boolean;
  disabled?: boolean;
  variant?: ButtonVariant;

  [prop: string]: any;
}

type ButtonProps =
  | (React.ButtonHTMLAttributes<HTMLButtonElement> & BtnProps)
  | (React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkParams & BtnProps);

const getTextColor = memoize((variant: ButtonVariant, hex: string): string => {
  const contrast = extractRGB(getContrastColor(hex));

  switch (variant) {
    case ButtonVariant.default:
      return `rgb(${contrast.red},${contrast.green},${contrast.blue})`;
    case ButtonVariant.outlined:
    case ButtonVariant.transparent:
    case ButtonVariant.inverted:
    default:
      return hex;
  }
});

const getBackgroundColor = memoize(
  (variant: ButtonVariant, hex: string): string => {
    const contrast = extractRGB(getContrastColor(hex));

    switch (variant) {
      case ButtonVariant.transparent:
        return `rgba(${contrast.red},${contrast.green},${contrast.blue},.2)`;
      case ButtonVariant.inverted:
      case ButtonVariant.outlined:
        return "transparent";
      case ButtonVariant.default:
      default:
        return hex;
    }
  },
  (variant: ButtonVariant, hex: string): string => `${variant}-${hex}`
);

const getBackgroundHoverColor = memoize(
  (variant: ButtonVariant, hex: string): string => {
    const { red, green, blue } = extractRGB(hex);
    const hoverRed = Math.min(Math.round(red * 1.78), 255);
    const hoverGreen = Math.min(Math.round(green * 1.09), 255);
    const hoverBlue = Math.min(Math.round(blue * 1.2), 255);

    const contrast = extractRGB(getContrastColor(hex));

    switch (variant) {
      case ButtonVariant.transparent:
        return `rgba(${contrast.red},${contrast.green},${contrast.blue},.3)`;
      case ButtonVariant.inverted:
        return `rgba(${red},${green},${blue},.2)`;
      case ButtonVariant.outlined:
        return `rgba(${contrast.red},${contrast.green},${contrast.blue},.05)`;
      case ButtonVariant.default:
      default:
        return `rgb(${hoverRed},${hoverGreen},${hoverBlue})`;
    }
  },
  (variant: ButtonVariant, hex: string): string => `${variant}-${hex}`
);

const ButtonBase = styled.button.attrs<ButtonProps>(({ color, variant }) => ({
  color: color || lightStrongAdditional,
  variant: variant || ButtonVariant.default,
}))<ButtonProps>`
  background: ${({ color, variant }) => getBackgroundColor(variant, color)};
  border: solid 1px ${({ variant }) => (variant === ButtonVariant.outlined ? "currentColor" : "transparent")};
  border-radius: 4px;
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: ${({ color, variant }) => getTextColor(variant, color)};
  padding: 10px 20px;
  transition: background-color 250ms ease, box-shadow 250ms ease;

  @media screen and ${MEDIA_QUERY.xl} {
    font-size: 14px;
    ${({ compact }) => (!compact ? "padding: 15px 30px;" : "")}
  }

  &:not(:disabled) {
    cursor: pointer;

    &:hover {
      background: ${({ color, variant }) => getBackgroundHoverColor(variant, color)};
      ${({ variant }) => variant === ButtonVariant.default && "box-shadow: 0 4px 8px 0 rgba(22, 8, 41, 0.3);"}
    }

    &:active {
      background: ${({ color, variant }) => getBackgroundColor(variant, color)};
      ${({ variant }) => variant === ButtonVariant.default && "box-shadow: 0 2px 4px 0 rgba(22, 8, 41, 0.3);"}
    }
  }

  &:disabled {
    background: #e9e8ec;
    color: #bdbbc7;
  }
`;

const Button: React.FC<ButtonProps> = forwardRef(({ children, ...props }, ref) => {
  if ("href" in props || "route" in props) {
    return React.createElement(ButtonBase, { as: Link, ...props, ref }, children);
  }

  return React.createElement(ButtonBase, { ...props, ref }, children);
});

/**
 * @deprecated This component is deprecated and will be removed in future releases.
 */
export default styled(Button)``;
