import React from "react";

import { Container } from "ui/container";
import { OfferBoxes } from "components/offer-boxes";
import { ActivationMethod } from "resources/AudiotekaApi";

import { OfferTop } from "./offer-top";
import { OfferFooter } from "./offer-footer";
import css from "./home-offer-boxes.module.scss";

interface Props {
  id: string;
  activationMethods: ActivationMethod[];
}

export function HomeOfferBoxes({ id, activationMethods }: Props) {
  return (
    <div className={css.homeOfferBoxes} id={id}>
      <Container className={css.container}>
        <OfferTop />
        <OfferBoxes methods={activationMethods} />
        <OfferFooter />
      </Container>
    </div>
  );
}
