import React from "react";
import { useTranslations } from "next-intl";

import Link from "components/link";
import { Accordion } from "ui/accordion";
import { ContainerBase } from "modules/DesignSystem/components/Container";

import css from "./faq.module.scss";

const pairNumbers = ["1", "2", "3", "4", "5", "6"] as const;

export function Faq() {
  const t = useTranslations("home.faq");

  return (
    <ContainerBase className={css.faq}>
      <h2 className={css.title}>{t("title")}</h2>
      <p className={css.description}>
        {t("description.text")}
        <Link route="helpCenter">{t("description.link_label")}</Link>
      </p>
      <div className={css.list}>
        {pairNumbers.map((pairNumber) => (
          <Accordion key={pairNumber} id={`question-${pairNumber}`} title={t(`question${pairNumber}`)}>
            {t(`answer${pairNumber}`)}
          </Accordion>
        ))}
      </div>
    </ContainerBase>
  );
}
