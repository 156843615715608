import React, { PropsWithChildren, ReactNode } from "react";

import Markdown from "components/markdown";
import { NewIcon } from "../NewIcon";

import css from "./accordion.module.scss";

interface Props {
  id: string;
  defaultOpen?: boolean;
  title: ReactNode;
}

export const Accordion = ({ id, defaultOpen, title, children }: PropsWithChildren<Props>) => {
  const textContent = typeof children === "string" ? <Markdown text={children} /> : children;

  return (
    <div className={css.accordion}>
      <input className={css.input} type="checkbox" defaultChecked={defaultOpen} id={id} />
      <label className={css.title} htmlFor={id}>
        {title}
        <NewIcon className={css.titleIcon} icon="chevron-down" width={24} height={24} />
      </label>
      <div className={css.animationWrapper}>
        <div className={css.contentWrapper}>
          <div className={css.content}>{textContent}</div>
        </div>
      </div>
    </div>
  );
};
