import React, { useCallback, useRef, useState } from "react";
import { useTranslations } from "next-intl";

import { deeplinkTranslator } from "modules/App/deeplinkTranslator";
import Title from "modules/DesignSystem/components/Title";
import { ContainerBase } from "modules/DesignSystem/components/Container";
import Button from "modules/DesignSystem/components/Button";
import { ItemSetsSection } from "resources/AudiotekaApi";

import TeaserGroup from "./teaser-group";
import css from "./item-sets.module.scss";

export const ItemSets = ({ section }: { section: ItemSetsSection }) => {
  const { elements, deeplink, label, title } = section;

  const revealBtnRef = useRef<HTMLButtonElement>();

  const t = useTranslations();

  const [isMobileReveal, setMobileReveal] = useState(false);

  const toggleMobileReveal = useCallback(() => {
    setMobileReveal((reveal) => {
      if (reveal && revealBtnRef.current) {
        const oldBtnTop = revealBtnRef.current.getBoundingClientRect().top;

        setTimeout(() => {
          const newBtnTop = revealBtnRef.current.getBoundingClientRect().top;
          const diff = newBtnTop - oldBtnTop;

          if (diff !== 0) {
            window.scrollBy({ behavior: "auto", top: diff });
          }
        });
      }

      return !reveal;
    });
  }, []);

  return (
    <ContainerBase className={css.itemSets}>
      <Title center>{title}</Title>
      <div className={css.list}>
        {elements.map((el) => (
          <TeaserGroup
            isMobileReveal={isMobileReveal}
            key={el.banner_url}
            label={el.label}
            bannerUrl={el.banner_url}
            labelColor={el.label_color}
            link={deeplinkTranslator(el.deeplink, el.slug)}
            accessibilityLabel={el.accessibility_label}
          />
        ))}
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {deeplink && label ? (
        <Button className={css.moreButton} {...deeplinkTranslator(deeplink)}>
          {label}
        </Button>
      ) : elements.length > 3 ? (
        <Button className={css.revealButton} ref={revealBtnRef} onClick={toggleMobileReveal}>
          {isMobileReveal ? t("home.item_sets.hide") : t("home.item_sets.reveal")}
        </Button>
      ) : null}
    </ContainerBase>
  );
};
