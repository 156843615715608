import React from "react";

import { Container } from "ui/container";
import { Text } from "ui/text";
import Markdown from "components/markdown";
import { ParagraphSection } from "resources/AudiotekaApi";

import css from "./paragraph.module.scss";

interface Props {
  section: ParagraphSection;
}

export function Paragraph({
  section: {
    elements: [element],
  },
}: Props) {
  if (element.layout === "page-info") {
    return (
      <Container className={css.pageInfo}>
        {element.title && (
          <Text className={css.title} as="h1" type="heading3">
            {element.title}
          </Text>
        )}
        {element.description && (
          <div className={css.description}>
            <Markdown text={element.description} />
          </div>
        )}
      </Container>
    );
  }

  return (
    <Container className={css.paragraph}>
      <Markdown
        options={{
          customizedHeaderId: true,
          rawHeaderId: true,
          simplifiedAutoLink: true,
          tables: true,
        }}
        text={element.description}
      />
    </Container>
  );
}
