import React, { ComponentProps } from "react";

import { BannerCarousel } from "components/banner-carousel";
import { BannerCarouselSection } from "resources/AudiotekaApi";

import { useHome } from "../home.hooks";
import { getGTMElement, trackCTAHomeEvent } from "../home.utils";

type Props = Omit<ComponentProps<typeof BannerCarousel>, "onClick" | "className">;

export function HomeBannerCarousel({ section, lazyLoading }: Props) {
  const home = useHome();

  return (
    <BannerCarousel
      className="home-carousel"
      section={section}
      lazyLoading={lazyLoading}
      onClick={(element) => {
        home.sendEvent(
          "select",
          getGTMElement(section as BannerCarouselSection, (section as BannerCarouselSection).elements.indexOf(element))
        );
        trackCTAHomeEvent("banner-carousel", element.accessibility_label);
      }}
    />
  );
}
