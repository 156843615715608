import React from "react";
import classNames from "classnames";

import { Container } from "ui/container";
import { Text as TextBase } from "ui/text";
import { ButtonLink } from "ui/button/button-link";

import css from "./hero.module.scss";

type HeroProps = {
  backgroundUrl: string;
  bannerUrl: string;
  className?: string;
  children: React.ReactNode;
};

const HeroContainer = React.forwardRef<HTMLDivElement, HeroProps>(
  ({ backgroundUrl, bannerUrl, children, className }, ref) => {
    return (
      <div
        ref={ref}
        className={classNames(css.wrapper, className)}
        style={{ backgroundImage: `url(${backgroundUrl}?auto=format)` }}
      >
        <Container className={css.container}>
          <div className={css.content}>{children}</div>
          <div className={css.bannerContainer}>
            <img
              alt=""
              className={css.banner}
              srcSet={`${bannerUrl}?w=500&auto=format 400w, ${bannerUrl}?w=900&auto=format 700w`}
            />
          </div>
        </Container>
      </div>
    );
  }
);

const Header = ({ children, ...props }: React.ComponentProps<typeof TextBase>) => {
  return (
    <div className={css.header}>
      <TextBase className={css.headerText} {...props}>
        {children}
      </TextBase>
    </div>
  );
};

type TextProps = React.ComponentProps<typeof TextBase> & {
  color: string;
};

const Text = ({ color, children, ...props }: TextProps) => {
  const titleColor = color.startsWith("#") ? color : `#${color}`;

  return (
    <TextBase className={css.text} style={{ color: titleColor }} {...props}>
      {children}
    </TextBase>
  );
};

type ButtonProps = React.ComponentProps<typeof ButtonLink> & {
  bgColor: string;
  textColor: string;
};

const Button = ({ bgColor, textColor, children, ...props }: ButtonProps) => {
  const buttonBgColor = bgColor.startsWith("#") ? bgColor : `#${bgColor}`;
  const buttonTextColor = textColor.startsWith("#") ? textColor : `#${textColor}`;

  return (
    <ButtonLink className={css.button} style={{ backgroundColor: buttonBgColor, color: buttonTextColor }} {...props}>
      {children}
    </ButtonLink>
  );
};

export const Hero = {
  ...HeroContainer,
  Header,
  Text,
  Button,
} as React.ForwardRefExoticComponent<HeroProps & React.RefAttributes<HTMLDivElement>> & {
  Header: typeof Header;
  Text: typeof Text;
  Button: typeof Button;
};
