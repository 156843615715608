import React from "react";

import TeaserList from "components/teaser-list";
import { ProductGridSection } from "resources/AudiotekaApi";
import { PricelessTeaser } from "components/teaser/priceless-teaser";

import css from "./product-grid.module.scss";

export const ProductGrid = ({ lazyLoading, section }: { lazyLoading: boolean; section: ProductGridSection }) => {
  const { id, title, deeplink, label, tracking_id: trackingId, web_deeplink } = section;
  const more = (web_deeplink || deeplink) && label ? { link: web_deeplink || deeplink, text: label } : undefined;
  const elements = section._embedded["app:product"].slice(0, 20);

  return (
    <div className={css.productGrid}>
      <TeaserList
        title={title}
        elements={elements}
        trackingId={trackingId || id}
        more={more}
        renderTeaser={({ product, onClick }) => (
          <PricelessTeaser product={product} onClick={onClick} lazyLoading={lazyLoading} />
        )}
      />
    </div>
  );
};

export default ProductGrid;
